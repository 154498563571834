import {mapState} from 'vuex';
import remove from '../../mixins/remove';

export default {
    name: "Predictions",
    data() {
        return {
            resultDialog: false,
            activeResultItem: null,
        }
    },
    computed: {
        ...mapState({
            items: (state) => state.predictions.items,
        }),
        headers() {
            return [
                {text: 'First team', value: "firstName"},
                {text: 'Second team', value: "secondName"},
                {text: 'Prediction', value: "resultText"},
                {text: 'Result', value: "isEnded2"},
                {text: '', value: "isEnded", align: "center"},
                {
                    text: 'Edit',
                    value: "actions",
                    align: "end",
                },
            ];
        },
    },
    methods: {
        removeItem() {
            this.$store.dispatch("predictions/deleteItem", this.selected);
            this.clearDialog();
        },
        publishItem(e) {
            this.activeResultItem = e;
            this.resultDialog = true;
        },
        approveResult(e) {
            this.$store.dispatch("predictions/approveResult", {
                id: this.activeResultItem,
                result: {
                    isSucceed: e,
                },
            }).then(() => {
                this.activeResultItem = null;
                this.resultDialog = false;
            })
        },
        
    },
    created() {
        this.$store.dispatch('predictions/getItems');
    },
    mixins: [remove],
};